<template>
  <div id="app">
    <div v-if="isPC" class="iframe-container">
      <iframe
        src="/"
        width="700"
        height="100%"
        frameborder="0"
      ></iframe>
      
    </div>
    <div v-else>
      <router-view></router-view>
      <Footer />
    </div>
    
  </div>
</template>

<script>
import Footer from './common/Footer';

export default {
  name: 'app',
  components: {
    Footer,
  },
  data() {
    return {
      isPC: false, // 判断是否为 PC 端
    };
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info',
      }).then((res) => {
        if (!localStorage.getItem('token')) {
          this.$router.push({ path: '/Login' });
        }
        this.$store.commit('setBaseInfoValue', res.data);
      });
    },
    checkDevice() {
      this.isPC = window.innerWidth > 768; // 768px 以上为 PC
    },
  },
  created() {
    this.getBaseInfo();
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice); // 移除事件监听器
  },
};
</script>

<style>
body .van-toast {
  font-size: 30px;
  padding: 30px;
  line-height: 50px;
  width: 45%;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*, :after, :before {
  box-sizing: border-box;
}

.iframe-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 使容器高度为视口高度 */
  background-color: #f0f0f0; /* 背景颜色 */
}
</style>
